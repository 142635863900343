body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.mainContent {
    background-image: url("resources/images/background.webp");
    background-repeat: no-repeat;
    background-size: 100vw;
    background-position: top;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    font-style: normal;
}
h1 {
    font-family: 'Playfair Display', serif;
    font-weight: 300;
    font-style: normal;
}
h2{
    padding-bottom: 5px;
}

/* Paragraphs */
p, li {
    font-family: 'Playfair Display', serif;
    color: black;

}
